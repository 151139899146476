import * as React from "react";
import Section from "../section";
import Container from "../container";
import characterMaleCut from "../../images/character-male-cut.png";
import characterLadyCut from "../../images/character-lady-cut.png";
import SectionSwitcher, { SectionItem } from "../section-switcher";
import AnimateOnScroll from "../animate-on-scroll";

interface TopSectionProps {
  pageTitle: string;
  sections: SectionItem[];
}

export default function TopSection({ pageTitle, sections }: TopSectionProps) {
  return (
    <Section id={"top"} footer={<TopSectionFooter sections={sections} />}>
      <Container className={"justify-center flex-wrap gap-8"}>
        <AnimateOnScroll ease={"ease-in"} transition={"fade-in"}>
          <div className={"flex w-full justify-center items-center relative"}>
            <h1
              className={
                "text-5xl md:text-7xl lg:text-9xl text-center uppercase font-bold"
              }
            >
              {pageTitle}
            </h1>
          </div>
        </AnimateOnScroll>
      </Container>
    </Section>
  );
}

function TopSectionFooter({ sections }: { sections: SectionItem[] }) {
  return (
    <Container className={"justify-between items-center relative"}>
      <div className={"self-end flex-1 flex w-1/4 md:w-auto"}>
        <img
          alt="Character depiction of Vien from Eddy and Wolff"
          src={characterMaleCut}
        />
      </div>
      <div className={"flex flex-grow flex-col items-center"}>
        <div className={"flex gap-4"}>
          <SectionSwitcher type={"next"} sections={sections} />
        </div>
      </div>
      <div className={"self-end flex-1 w-1/4 lg:w-auto flex justify-end"}>
        <img
          alt="Character depiction of Thao from Eddy and Wolff"
          src={characterLadyCut}
        />
      </div>
    </Container>
  );
}
