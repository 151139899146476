import * as React from "react";
import Section from "../section";
import Container from "../container";
import SectionSwitcher, { SectionItem } from "../section-switcher";

interface MidSectionProps {
  prevSection?: SectionItem;
  nextSection?: SectionItem;
  className?: string;
  slug: string;
}

export default function MidSection({
  prevSection,
  nextSection,
  className = "",
  slug,
  children,
}: React.PropsWithChildren<MidSectionProps>) {
  return (
    <Section
      id={slug}
      className={className}
      footer={nextSection && <SectionFooter section={nextSection} />}
      header={prevSection && <SectionHeader section={prevSection} />}
    >
      {children}
    </Section>
  );
}

function SectionHeader({ section }: { section: SectionItem }) {
  return (
    <Container className={"justify-center items-center relative"}>
      <SectionSwitcher type={"prev"} sections={[section]} />
    </Container>
  );
}

function SectionFooter({ section }: { section: SectionItem }) {
  return (
    <Container className={"justify-center items-center relative"}>
      <SectionSwitcher type={"next"} sections={[section]} />
    </Container>
  );
}
