import * as React from "react";

interface UnderlayTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  className?: string;
  overlay?: boolean;
  size?: "small" | "medium" | "large";
}

export default function UnderlayTitle({
  children,
  overlay = false,
  size,
  className = "",
  ...props
}: React.PropsWithChildren<UnderlayTitleProps>) {
  const sizes = {
    small: "text-5xl lg:text-7xl",
    medium: "text-7xl lg:text-big",
    large: "text-9xl lg:text-super",
  };

  return (
    <h1
      {...props}
      className={`${!overlay && "lg:absolute"}  ${
        sizes[size ?? "large"]
      } uppercase font-bold ${className}`}
    >
      {children}
    </h1>
  );
}
