import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

interface CharactersProps {
  className?: string;
  width?: number;
}

interface CharacterProps {
  className?: string;
  width: number;
}

export function CharacterLady({ className }: CharacterProps) {
  const width = 160 * 1.12;
  return (
    <StaticImage
      src={"../../images/character-lady.png"}
      width={width}
      className={className}
      placeholder={"tracedSVG"}
      alt={"Character depiction of Thao from Eddy and Wolff"}
    />
  );
}

export function CharacterMale({ className }: CharacterProps) {
  const width = 160;

  return (
    <StaticImage
      src={"../../images/character-male.png"}
      width={width}
      className={className}
      placeholder={"tracedSVG"}
      alt={"Character depiction of Thao from Eddy and Wolff"}
    />
  );
}

export default function Characters({
  className = "",
  width = 160,
}: CharactersProps) {
  return (
    <div className={className}>
      <CharacterMale className={"max-w-half md:max-w-full"} width={width} />
      <CharacterLady className={"max-w-half md:max-w-full"} width={width} />
    </div>
  );
}
