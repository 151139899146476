import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import TopSection from "../components/top-section";
import MidSection from "../components/mid-section";
import { CharacterLady, CharacterMale } from "../components/characters";
import UnderlayTitle from "../components/underlay-title";

export default function OurStoryPage() {
  const sections = {
    ourStory1: {
      label: "Our Story",
      slug: "our-story",
    },
    ourStory2: {
      label: "Our Story",
      slug: "our-story-2",
    },
    top: {
      label: "Back",
      slug: "top",
    },
  };
  return (
    <Layout title={"Our Story"}>
      <TopSection pageTitle={"Our Story"} sections={[sections.ourStory1]} />
      <MidSection
        className={"bg-white"}
        slug={sections.ourStory1.slug}
        prevSection={sections.top}
        nextSection={sections.ourStory2}
      >
        <Container className={"justify-between items-center"}>
          <CharacterMale className={"hidden lg:block"} width={170} />
          <div
            className={
              "flex px-4 lg:w-1/2 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle overlay={true} className={"text-primary"}>
              Story
            </UnderlayTitle>
            <p
              className={
                "relative text-2xl md:text-2xl lg:text-4xl lg:text-center uppercase font-semibold mb-12"
              }
            >
              We want to take you way back to where we came from, a time we are
              most reminiscent of. Yes we were born in Vietnam but when we are
              asked the question, “where do you guys come from?”, we say,
              “Canberra!”. It runs in our veins, our parents, our childhood, our
              friends, our favourite 90’s music and everything we ate at family
              gatherings rings true to us.
            </p>

            <p className={"text-xl md:px-0 md:col-count-2 md:col-gap-2"}>
              Our story of Eddy + Wolff means so much to us.
              <br />
              Thao: I grew up on Eddy Cres
              <br />
              Vien: And I grew up on Wolff Cres.
              <br />
              Its where we met and when our journey all began. This is why we
              created this place so we can all meet you here.
              <br />
              Imagine a small bar in Asia, whether it be Japan or Hong Kong. Off
              the beaten track meaning somewhere where you wouldn’t think you’d
              find us. A place you’d step into and feel like it’s not something
              that’s on the Gold Coast.
              <br />
              <br className={"md:hidden"} />
              A cosy intimate space that transports you into another time, that
              will immerse you in flavours that are true and authentic, full of
              heart and soul from everything we loved eating growing up in
              Canberra and from our travels from all over Asia.
              <br />
              Our space will allow you to reminisce about your old school days
              from the music we play or your travels to Asia from the flavours
              in our dishes, we would like you to feel a sense of belonging, a
              connection that will take you back to where we ‘all’ came from.
              <br />
            </p>
          </div>
          <CharacterLady className={"hidden lg:block"} width={170} />
        </Container>
      </MidSection>
      <MidSection
        className={"bg-primary"}
        slug={sections.ourStory2.slug}
        prevSection={sections.ourStory1}
      >
        <Container className={"justify-center items-center"}>
          <div
            className={
              "flex px-4 lg:w-1/2 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle overlay={true} className={"text-white mb-4"}>
              Story
            </UnderlayTitle>

            <p className={"mb-4 text-xl relative"}>
              We are so fortunate to have grown up most of our lives in
              Australia. Having only arrived here when we were both too young
              for school, and fresh off the boat from Vietnam, our family clung
              onto our new found freedom and instilled in us the value of hard
              work and most of all the power to fight for your dreams.
            </p>

            <p className={"mb-12 text-xl relative"}>
              At a time that should’ve pushed us in another direction, we left
              our family nest and started to travel. Only having been to
              Melbourne for the first time in 2014, gave us a new lease on our
              destined journey. This opened the doors for us into becoming real
              foodies and opened our eyes to a world that was always under our
              noses but never seen in a light that we see it today.
            </p>
            <p
              className={
                "relative text-2xl md:text-2xl lg:text-4xl uppercase font-semibold mb-12"
              }
            >
              If this is the first time you have heard about us, you’ll have to
              go and visit our first restaurant, Can Tho Kitchen Bar in Runaway
              Bay. That was about our parents journey but Eddy + Wolff is about
              our Journey.
            </p>
          </div>
        </Container>
      </MidSection>
    </Layout>
  );
}
