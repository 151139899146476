import * as React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export interface SectionItem {
  label: string;
  slug: string;
}

interface SectionSwitcherProps {
  type: "next" | "prev";
  sections: SectionItem[];
}

export default function SectionSwitcher({
  type,
  sections,
}: SectionSwitcherProps) {
  const [hover, setHover] = React.useState(false);

  return (
    <div className={"flex flex-col items-center"}>
      {type === "prev" && (
        <IoIosArrowUp
          className={`transition transform ${hover ? "-translate-y-1" : ""}`}
          size={38}
        />
      )}
      <div className={"flex gap-4"}>
        {sections.map((section) => {
          return (
            <a
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              key={section.slug}
              href={`#${section.slug}`}
              className={
                "flex flex-col items-center text-lg uppercase font-semibold hover:underline"
              }
            >
              <span>{section.label}</span>
            </a>
          );
        })}
      </div>
      {type === "next" && (
        <IoIosArrowDown
          className={`transition transform ${hover ? "translate-y-1" : ""}`}
          size={38}
        />
      )}
    </div>
  );
}
